import { ButtonCommon, ModalCommon } from '../../../components'
import styles from './index.module.scss'
import { useReactToPrint } from 'react-to-print'
import { useRef } from 'react'
import { staffUi } from '../../../until/text/ui'
import QRCode from 'qrcode.react'
import { QR_CODE } from '../../../until/constant'
import { IMeter } from '../../../models'
import { usePrintMeter } from '../../../hooks/query/meter'

interface IProps {
  data?: IMeter
  title: string
  detailModal?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

export const ModalQRMeter = ({ detailModal, title, visible, onOk, onCancel, data }: IProps) => {
  const componentRef = useRef(null)
  const { mutateAsync: mutatePrintAsync, isLoading: isPrintLoading } = usePrintMeter()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handleConvertQrCode = async () => {
    await mutatePrintAsync()
    handlePrint()
  }

  function getCanvasBlob(mycanvas: HTMLCanvasElement, id?: string) {
    return new Promise<File>(function (resolve) {
      mycanvas.toBlob((blob: any) => {
        resolve(new File([blob], `qrcode-staff${id}.png`, { type: 'image/png' }))
      })
    })
  }

  const download = async () => {
    const element = document.createElement('a')
    const canvas = document.getElementById(`canvas${data?.qr_code}`) as HTMLCanvasElement
    const fileDownload = await getCanvasBlob(canvas, data?.qr_code)
    element.href = URL.createObjectURL(fileDownload)
    element.download = `${data?.building_code}-${data?.room_no}-${data?.subject_code}.png`
    element.click()
  }

  return (
    <ModalCommon
      title={title}
      detailModal={detailModal}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="600px"
      destroyOnClose={true}
    >
      <div className={styles.container}>
        <div className={styles.img__container} style={{ padding: 0 }}>
          {data?.qr_code && (
            <QRCode
              level={QR_CODE.LEVEL}
              id={`canvas${data?.qr_code}`}
              value={data?.qr_code}
              renderAs="canvas"
              includeMargin={true}
              size={158}
            />
          )}
        </div>
        <div className={styles.form__footer}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {staffUi.cancel}
          </ButtonCommon>
          <ButtonCommon type="ghost" onClick={download}>
            {staffUi.download}
          </ButtonCommon>
          <ButtonCommon loading={isPrintLoading} type="primary" onClick={handleConvertQrCode} style={{ letterSpacing: '-2px' }}>
            {staffUi.print}
          </ButtonCommon>
        </div>
        <div className={styles.clone__qrcode} ref={componentRef}>
          {data && (
            <QRCode
              level={QR_CODE.LEVEL}
              size={QR_CODE.SIZE}
              id={`canvas${data?.qr_code}`}
              value={data?.qr_code}
              renderAs="canvas"
            />
          )}
          <p>{`${data?.building_code}-${data?.room_no}-${data?.subject_code}`}</p>
        </div>
      </div>
    </ModalCommon>
  )
}
